import React from "react";
//import { Link } from "gatsby";
import Layout from "../components/layout";
import Subscribe from "../components/subscribe";
//import Layout from "../components/layout";

const AboutPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <p>
          Here's some very light matter that keeps floating over my brain.
          Mostly physic-y and math-y stuff. If you have some of this over your
          brain too, click the "Ask a Question" button and I'll try to see what
          I can do with them.
        </p>
        <br />
        <br />
        <Subscribe />
      </Layout>
    </div>
  );
};

/*
export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
          }
          excerpt
        }
      }
    }
  }
`;*/

export default AboutPage;
